import { Flex, Image, Link, Text } from '@chakra-ui/react';
import { mockedFooter } from '@app/interfaces/temporary';
import { FooterLinks } from './FooterLinks';

export const Footer = (): JSX.Element => {
  return (
    <Flex
      color="white"
      background="black"
      width="full"
      flexDir={{ base: 'column', lg: 'row' }}
      gap={{ base: '1rem', lg: '3.5rem' }}
      alignItems="flex-start"
      justifyContent="space-between"
      padding="5rem 3rem"
    >
      <Image
        src="/images/eqi-research-logo.svg"
        alt="eqi-logo"
        w="200px"
        h="66px"
      />
      <Text
        fontSize=".65rem"
        maxW="1192px"
        lineHeight="1rem"
        fontWeight="400"
        textAlign="justify"
      >
        A EQI/RESEARCH preza pela qualidade da informação e atesta a apuração de
        todo o conteúdo por sua equipe, ressaltando que os relatórios da
        EQI/RESEARCH não representam ofertas, negociação de valores mobiliários,
        ou outros instrumentos financeiros. As decisões de investimentos e
        estratégias financeiras devem ser realizadas pelos próprios leitores. A
        EQI/RESEARCH não se responsabiliza por perdas, danos (diretos, indiretos
        e incidentais), custos e lucros cessantes. Para maiores informações,
        veja a{' '}
        <Link
          href="https://www.in.gov.br/en/web/dou/-/resolucao-cvm-n-20-de-25-de-fevereiro-de-2021-305383862"
          target="_blank"
          textDecor="underline"
        >
          INSTRUÇÃO CVM N° 20, DE 25 DE FEVEREIRO DE 2021
        </Link>{' '}
        e também o{' '}
        <Link
          href="https://www.apimec.com.br/apimec-brasil/codigo-de-etica/"
          target="_blank"
          textDecor="underline"
        >
          Código de Ética da Apimec para o Analista de Valores Mobiliários.
        </Link>
      </Text>

      <FooterLinks userTermsLink={mockedFooter.rodape?.paginasRodape} />
    </Flex>
  );
};
