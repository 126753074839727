import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import projectConfig from '../../package.json';

export const theme = extendTheme({
  config: {
    cssVarPrefix: projectConfig.name,
  },
  fonts: {
    heading: `"Roboto", ${baseTheme.fonts.heading}`,
    body: `"Roboto", ${baseTheme.fonts.body}`,
  },
  sizes: {
    container: {
      xxl: '1920px',
    },
  },
  colors: {
    orange: {
      500: '#EE9258',
      700: '#F85631',
    },
    brown: {
      400: '#C0A97A',
    },
    gray: {
      9: '#EBE4D5',
      10: '#EEEFF0',
      30: '#D8E0E4',
      40: '#C5D1D7',
      100: '#EDF2F7',
      300: '#5A636D',
      350: '#5A5A5A',
      400: '#929292',
      450: '#B2C1C9',
      500: '#BCC2C8',
    },
    green: {
      50: '#C5EDE1',
      100: '#3CD9A6',
      150: '#3ABB93',
      200: '#21946F',
      300: '#164738',
      350: '#174738',
      400: '#132D24',
      650: '#00271BCC',
    },
    black: {
      50: '#0B0B0F',
      100: '#1F2325',
      200: '#1D242B',
      500: '#151B1E',
    },
    white: {
      light: '#F7F7F7',
      100: '#F3F4F6',
      150: '#F2F5F8',
      400: '#F7F7F7',
      500: '#F8F8F8',
      600: '#F2F2F2',
    },
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'green.300',
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'green.300',
            borderColor: 'green.300',
          },
        },
      },
    },
  },
});
