import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { ApolloProvider } from '@apollo/client';
import { envConfig } from '@app/config/env';
import { makeServer } from '@app/services/mirage';
import { queryClient } from '@app/services/react-query';
import { WalletProvider } from '@app/context/WalletContext';
import { useApolloClient } from '@app/hooks/useApolloClient';
import { DefaultLayout } from '@app/components/Layouts/DefaultLayout';
import { pageview } from '@app/utils/gtag';
import { theme } from '@app/styles/theme';
import '@app/styles/global.css';

if (envConfig.isDevelopment && envConfig.isMirageEnabled) {
  makeServer();
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const client = useApolloClient();

  const { events } = useRouter();

  const handleRouteChange = (url: string) => {
    if (envConfig.isProduction) pageview(url);
  };

  useEffect(() => {
    envConfig.isProduction &&
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
      });
  }, []);

  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  return (
    <ChakraProvider theme={theme}>
      <Head>
        <title>Análise de Carteira de FIIs - EQI Research</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.deHydratedState}>
          <ApolloProvider client={client}>
            <WalletProvider>
              <DefaultLayout>
                <Component {...pageProps} />
              </DefaultLayout>
            </WalletProvider>
          </ApolloProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </Hydrate>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default MyApp;
