import NextLink from 'next/link';
import { Flex, Link } from '@chakra-ui/react';

export const FooterLinks = ({
  userTermsLink,
}: FooterLinksProps): JSX.Element => {
  return (
    <Flex
      flexDir="column"
      gap="1rem"
      fontSize="xs"
      alignItems={{ base: 'flex-start', lg: 'flex-end' }}
    >
      {userTermsLink.map(({ link, nome }) => (
        <Link key={nome} as={NextLink} href={link} textDecor="underline">
          {nome}
        </Link>
      ))}
    </Flex>
  );
};

type FooterLinksProps = {
  userTermsLink: { nome: string; link: string }[];
};
