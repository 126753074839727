var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"V5APpjtOMWX65Aos_89RC"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { envConfig } from '@app/config/env';

Sentry.init({
  enabled: envConfig.isProduction,
  dsn: envConfig.sentryDsn,
  debug: false,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
    new Sentry.BrowserTracing(),
  ],
  profilesSampleRate: 0.2,
  tracesSampleRate: 0.5,
  replaysOnErrorSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
});
