import type { ReactNode } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { Footer } from '@app/components/Elements/Footer';

export const DefaultLayout = ({
  children,
}: DefaultLayoutProps): JSX.Element => {
  const imageSize = {
    h: 'clamp(744px, 100vh, 1024px)',
    w: 'full',
  };
  return (
    <Flex w="full" flexDir="column" zIndex={1}>
      <Box
        position="absolute"
        zIndex={-1}
        background="linear-gradient(70deg, #000 1.55%, rgba(0, 0, 0, 0.5) 25.03%, rgba(0, 0, 0, 0.00) 100%)"
        {...imageSize}
      />
      <Image
        zIndex={-2}
        position="absolute"
        src="/images/fundo-lp.png"
        alt="Mulher de negócios utilizando um tablet"
        objectFit="cover"
        objectPosition={{ base: '70%', lg: 'center' }}
        {...imageSize}
      />
      <Flex flexDir="column">{children}</Flex>
      <Footer />
    </Flex>
  );
};

type DefaultLayoutProps = {
  children: ReactNode;
};
